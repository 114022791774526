<template>
  <div class="page-mates buy-char">
    <div class="toggle">
      <button
        class="toggle-btn"
        :class="{ active: tab === 'BUY' }"
        @click="setTab('BUY')"
      >
        {{ $t("buy-btn") }}
      </button>
      <button
        class="toggle-btn"
        :class="{ active: tab === 'SELL' }"
        @click="setTab('SELL')"
      >
        {{ $t("sell-sm-btn") }}
      </button>
    </div>

    <div v-if="tab === 'BUY'" class="buy-tab">
      <SelectCharacterList
        :show-own-csh="false"
        :loader-method-to-community-csh="'getCshOnMarket'"
        @selectMate="selectMate"
      />
    </div>

    <div v-if="tab === 'SELL'" class="sell-tab">
      <SelectCharacterList
        :show-community-csh="false"
        :loader-method-to-own-csh="'getUserCryptoshoppies'"
        @selectMate="selectMate"
      />
    </div>
  </div>
</template>

<script>
import SelectCharacterList from "../../components/lists/SelectCharacterList";

export default {
  name: "MarketCharacter",
  data() {
    return {
      tab: "BUY",
    };
  },
  methods: {
    setTab(tab) {
      this.tab = tab;
    },
    async selectMate(mate) {
      if (this.tab === "BUY") {
        await this.$router.push({
          path: `cryptoshoppie/${mate.id}`,
          query: {
            price: mate.price,
            commission: mate.commission,
            itemId: mate.itemId,
          },
        });
      } else {
        await this.$router.push({
          path: `cryptoshoppie/${mate.id}`,
          query: {
            sell: true,
          },
        });
      }
    },
  },
  components: { SelectCharacterList },
};
</script>

<style scoped></style>
